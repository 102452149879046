<template lang="pug">
div(v-if="$store.state.app.accessSySafra")
  v-container(fluid)
    DataFilter(:filterProps="filters", :items="clientes", :paginate="false")
      template(v-slot:actions)
        v-btn.mr-3.ml-3(color="primary", fab, small, @click="getClientes")
          v-icon mdi mdi-reload

      template(v-slot:default="{ items }")
        v-data-table.clientesTable(
          dense,
          :footer-props="footprops",
          :headers="dataTableHeaders",
          :items="items"
        )
          template(v-slot:item.ativo="{ item }")
            v-icon(v-if="item.ativo", color="success") fas fa-check-circle
            v-icon(v-else, color="error") fas fa-close

          template(v-slot:item.excecao="{ item }")
            v-tooltip(v-if="item.excecao", bottom)
              template(v-slot:activator="{ on }")
                v-icon(v-on="on", color="success") fas fa-check-circle
              span Tipo Base = {{ item.validacao.tipobase }} / Período = {{ item.validacao.periodo }}

          template(v-slot:item.actions="{ item }")
            v-btn(color="primary", icon, small, @click="seeClient(item)")
              v-icon mdi mdi-eye
            v-btn(color="primary", icon, small, @click="linkHistorico(item)")
              v-icon mdi mdi-history
            v-btn(color="primary", icon, small, @click="editClient(item)")
              v-icon mdi mdi-pencil

  v-dialog(
    v-model="editing",
    v-if="editing",
    max-width="800px",
    persistent,
    width="100%"
  )
    v-card
      v-card-title
        span Editar cliente - {{ cliente.idcliente }} - {{ cliente.nomecliente }}
      v-card-text
        v-container(fluid)
          v-form(ref="formEdit")
            v-row
              v-col(cols="12", sm="4", md="4")
                v-text-field(
                  v-model="cliente.serialhd",
                  label="Serial HD",
                  maxlength="12",
                  :disabled="true"
                )

              v-col(cols="12", sm="4", md="4", style="padding: 30px")
                v-select(
                  :items="listaHorasInteiras",
                  v-model="cliente.horarioinicial",
                  label="Horário Inicial",
                  :disabled="true",
                  dense
                )

              v-col(cols="12", sm="4", md="4", style="padding: 30px")
                v-select(
                  :items="listaHorasInteiras",
                  v-model="cliente.horariofinal",
                  label="Horário Final",
                  :disabled="true",
                  dense
                )

              v-col.mt-0.pt-0(cols="12", sm="12", md="12")
                span Intervalo entre os backups: {{ cliente.intervalo }} hora(s)
                v-slider(
                  v-model="cliente.intervalo",
                  min="1",
                  max="24",
                  step="1",
                  thumb-label,
                  prepend-icon="mdi-clock-outline",
                  :disabled="true"
                )
            v-row
              v-col.ma-0.pa-0.pl-4(cols="12", sm="12", md="12")
                span Regras de Exceção
                v-card.ma-0.pa-0.pl-4.pt-2(elevation="0", outlined)
                  v-checkbox(
                    v-model="habilitarConfiguracoesAvancadas",
                    dense,
                    label="Exceção para Validação do Backup"
                  )
                  v-row
                    v-col(cols="12", sm="4", md="4")
                      v-select.mx-1(
                        :items="tipoBase",
                        v-model="cliente.validacao.tipobase",
                        label="Tipo de base",
                        :disabled="!habilitarConfiguracoesAvancadas",
                        dense,
                        outlined
                      )
                    v-col(cols="12", sm="4", md="4")
                      v-select.mx-1(
                        :items="tipoPeriodo",
                        v-model="cliente.validacao.periodo",
                        label="Período",
                        :disabled="!habilitarConfiguracoesAvancadas",
                        dense,
                        outlined
                      )
      v-card-actions
        v-spacer
        v-btn(color="error", outlined, @click="editing = false")
          v-icon mdi mdi-close
          | Cancelar
        v-btn(color="success", @click="verificaClienteEmExecucao")
          v-icon(left) mdi mdi-content-save
          | Salvar

  v-dialog(
    v-model="viewing",
    v-if="viewing",
    max-width="800px",
    persistent,
    width="100%"
  )
    v-card
      v-card-title
        span Configuração do cliente
      v-card-text
        v-container(fluid)
          v-row(no-gutters)
            v-col(cols="12", sm="2", md="2")
              b Nome do bucket:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.bucket }}

        v-divider
        v-container(fluid)
          v-row(no-gutters)
            v-col(cols="12", sm="12", md="12")
              span Dados do cliente:
            v-col(cols="12", sm="2", md="2")
              b ID do cliente:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.idcliente }}
            v-col(cols="12", sm="2", md="2")
              b Serial HD:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.serialhd }}
            v-col(cols="12", sm="2", md="2")
              b Cliente:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.cliente.nome }}
            v-col(cols="12", sm="2", md="2")
              b Ativo:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.ativo ? "Sim" : "Não" }}
            v-col(cols="12", sm="2", md="2")
              b Horário inicial:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.horarioinicial }}
            v-col(cols="12", sm="2", md="2")
              b Horário final:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.horariofinal }}
            v-col(cols="12", sm="2", md="2")
              b Intervalo:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.intervalo }} hora(s)

        v-divider
        v-container(fluid)
          v-row(no-gutters)
            v-col(cols="12", sm="12", md="12")
              span Dados da conta de serviço:
            v-col(cols="12", sm="2", md="2")
              b ID:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.conta.uniqueId }}
            v-col(cols="12", sm="2", md="2")
              b E-mail:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.conta.email }}
            v-col(cols="12", sm="2", md="2")

        v-divider
        v-container(fluid)
          v-row(no-gutters)
            v-col(cols="12", sm="12", md="12")
              span Datas:
            v-col(cols="12", sm="2", md="2")
              b Data de criação:
            v-col(cols="12", sm="10", md="10")
              span {{ new Date(clienteView.criadoem).toLocaleString() }}
            v-col(cols="12", sm="2", md="2")
              b Última alteração:
            v-col(cols="12", sm="10", md="10")
              span {{ new Date(clienteView.atualizadoem).toLocaleString() }}

        v-divider
        v-container(v-if="clienteView.bucketInfo")
          v-row(no-gutters)
            v-col(cols="12", sm="12", md="12")
              span Dados do bucket:
            v-col(cols="12", sm="2", md="2")
              b Nome:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.bucketInfo.name }}
            v-col(cols="12", sm="2", md="2")
              b ID:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.bucketInfo.id }}
            v-col(cols="12", sm="2", md="2")
              b Localização:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.bucketInfo.location }}
            v-col(cols="12", sm="2", md="2")
              b Classe de armazenamento:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.bucketInfo.storageClass }}
            v-col(cols="12", sm="2", md="2")
              b Tamaho:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.bucketInfo.size }}
            v-col(cols="12", sm="2", md="2")
              b Data de criação:
            v-col(cols="12", sm="10", md="10")
              span {{ new Date(clienteView.bucketInfo.timeCreated).toLocaleString() }}
            v-col(cols="12", sm="2", md="2")
              b Última alteração:
            v-col(cols="12", sm="10", md="10")
              span {{ new Date(clienteView.bucketInfo.updated).toLocaleString() }}
            v-col(cols="12", sm="2", md="2")
              b Versão do protocolo:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.bucketInfo.versioningEnabled ? "Habilitado" : "Desabilitado" }}
            v-col(cols="12", sm="2", md="2")
              b Versão do ciclo de vida:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.bucketInfo.lifecycleEnabled ? "Habilitado" : "Desabilitado" }}
            v-col(cols="12", sm="2", md="2")
              b Versão do controle de acesso:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.bucketInfo.iamConfiguration.uniformBucketLevelAccess.enabled ? "Habilitado" : "Desabilitado" }}
            v-col(cols="12", sm="2", md="2")
              b Versão do controle de acesso:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.bucketInfo.iamConfiguration.uniformBucketLevelAccess.lockedTime ? new Date(clienteView.bucketInfo.iamConfiguration.uniformBucketLevelAccess.lockedTime).toLocaleString() : "Não definido" }}
            v-col(cols="12", sm="2", md="2")
              b Versão do controle de acesso:
            v-col(cols="12", sm="10", md="10")
              span {{ clienteView.bucketInfo.iamConfiguration.publicAccessPrevention ? "Habilitado" : "Desabilitado" }}

      v-card-actions
        v-btn(
          color="primary",
          :loading="searchingBucketInfo",
          @click="getBucketInfo(clienteView.id)"
        ) Dados do bucket
        v-spacer
        v-btn(color="primary", outlined, @click="closeView")
          v-icon mdi mdi-close
          | Fechar

  v-dialog(
    v-model="clienteIsInExecution",
    v-if="clienteIsInExecution",
    max-width="600px",
    persistent,
    width="100%"
  )
    v-card
      v-card-title.red.mb-4.white--text
        h3 Cliente com backup em execução
      v-card-text
        v-container(fluid)
          v-row
            v-col(cols="12", sm="12", md="12", align="center")
              h3.red--text Caso siga com a alteração o backup pode ser afetado e não será finalizado.
      v-card-actions
        v-spacer
        v-btn(
          color="error",
          outlined,
          @click="clienteIsInExecution = false; editing = false"
        )
          v-icon mdi mdi-close
          | Cancelar

        v-btn(
          color="success",
          @click="clienteIsInExecution = false; saveEditClient()"
        )
          v-icon(left) mdi mdi-check
          | Confirmar
</template>

<script>
import csapi from "@/api/csapi";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    isHomologation:
      process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "homologation",
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
    filters: { includeSearch: true, items: [] },
    dataTableHeaders: [
      {
        text: "ID",
        value: "id",
        width: "70",
        class: "pl-3 pr-0",
        cellClass: "pl-3 pr-0",
      },
      {
        text: "Cliente",
        value: "nomecliente",
        class: "pl-0 pr-3",
        cellClass: "pl-0 pr-3",
      },
      {
        text: "Serial HD",
        value: "serialhd",
        width: "130",
        class: "pl-0 pr-0",
        cellClass: "pl-0 pr-0",
      },
      {
        text: "Bucket",
        value: "bucket",
        width: "260",
        class: "pl-0 pr-0",
        cellClass: "pl-0 pr-0",
      },
      {
        text: "ID da chave",
        value: "uniqueId",
        width: "130",
        sortable: false,
        class: "pl-0 pr-0",
        cellClass: "pl-0 pr-0",
      },
      {
        text: "Ativo",
        value: "ativo",
        align: "center",
        width: "90",
        class: "pl-0 pr-0",
        cellClass: "pl-0 pr-0",
      },
      {
        text: "Tem Exceção?",
        value: "excecao",
        align: "center",
        width: "90",
        sortable: false,
        class: "pl-0 pr-0",
        cellClass: "pl-0 pr-0",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "180",
        class: "pl-0 pr-0",
        cellClass: "pl-0 pr-0",
      },
    ],
    tipoBase: ["NORMAL", "LOG", "AMBAS"],
    tipoPeriodo: ["DIARIO", "SEMANAL"],
    clientes: [],
    viewing: false,
    clienteView: {},
    searchingBucketInfo: false,
    adding: false,
    editing: false,
    deleting: false,
    listaHorasInteiras: [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ],
    clienteIsInExecution: false,
    clienteDeletingName: "",
    loadingNewClientes: false,
    clientesWithoutSysBackup: [],
    habilitarConfiguracoesAvancadas: false,
    habilitarConfiguracoesAvancadasAfter: false,
    cliente: {
      ativo: true,
      horarioinicial: null,
      horariofinal: null,
      intervalo: 1,
      idcliente: null,
      nomecliente: null,
      serialhd: null,
      validacao: {
        tipobase: null,
        periodo: null,
      },
    },
    footprops: {
      itemsPerPageOptions: [50, 100, 200, 300, 500, -1],
      showCurrentPage: true,
      showFirstLastPage: true,
      itemsPerPageAllText: "Todos",
      itemsPerPageText: "Itens por página",
    },
  }),

  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    async getClientes() {
      this.showLoader("Carregando clientes...");
      try {
        const res = await csapi.get("/sysafra/v1/sysbackup/clientes");

        if (res.status === 200) {
          console.log(res.data);

          res.data.forEach((row) => {
            row.nomecliente = row.cliente.nome;
            row.uniqueId = row.conta.uniqueId;
          });

          this.clientes = res.data;
          this.clientes.forEach(
            (cliente) => (cliente.excecao = cliente.validacao !== null)
          );
        }
      } catch (error) {
        console.error(error);
        this.$toast.error("Erro ao carregar clientes!");
      } finally {
        this.hideLoader();
      }
    },

    async verificaClienteEmExecucao() {
      if (!this.cliente.ativo) {
        const dateFinal = new Date().toISOString().substring(0, 10);
        const dateInit = new Date(new Date().getTime() - 1000 * 60 * 60 * 24)
          .toISOString()
          .substring(0, 10);

        const query = ""
          .concat(`?client=${this.cliente.idcliente}`)
          .concat(`&interval=${dateInit},${dateFinal}`)
          .concat("&include-details=true");

        const data = await csapi.get(`/sysafra/v1/sysbackup/item${query}`);

        if (data.data.length == 0) this.saveEditClient();
        else if (data.data[0].status === "EM_EXECUCAO")
          this.clienteIsInExecution = true;
        else this.saveEditClient();
      } else {
        this.saveEditClient();
      }
    },

    seeClient(cliente) {
      this.clienteView = cliente;
      this.viewing = true;
    },

    closeView() {
      this.viewing = false;
      this.clienteView = {};
    },

    linkHistorico(item) {
      localStorage.setItem("historyClientName", item.cliente.nome);
      this.$router.push(
        `/sysafra/sysbackup/clientes/${item.cliente.id}/historico-backups`
      );
    },

    async getBucketInfo(idConfig) {
      this.searchingBucketInfo = true;
      try {
        const res = await csapi.get(
          `/sysafra/v1/sysbackup/clientes/${idConfig}/bucket`
        );

        if (res.status === 200) this.clienteView.bucketInfo = res.data;
      } catch (error) {
        console.error(error);
        this.$toast.error("Erro ao carregar informações do bucket!");
      } finally {
        this.searchingBucketInfo = false;
      }
    },

    async deteleConfigValidacaoCliente() {
      try {
        await csapi.delete(
          `/sysafra/v1/sysbackup/clientes/${this.cliente.idcliente}/validacao`
        );
      } catch (error) {
        console.error(error);
      }
    },

    async saveNewClient() {
      const valid = await this.$refs.form.validate();
      if (!valid) return;

      if (
        parseInt(this.cliente.horarioinicial) >
        parseInt(this.cliente.horariofinal)
      ) {
        this.$toast.error(
          "Horário inicial deve ser menor que o horário final!"
        );
        return;
      }

      this.showLoader("Salvando novo cliente...");
      try {
        if (this.habilitarConfiguracoesAvancadas == false) {
          delete this.cliente.validacao;
        }

        const res = await csapi.post(
          "/sysafra/v1/sysbackup/clientes",
          this.cliente
        );

        if (res.status === 200) {
          this.$toast.success("Cliente salvo com sucesso!");
          this.adding = false;
          this.getClientes();
        }
      } catch (error) {
        const { data } = error.response;
        if (data && data.message) {
          this.$toast.error(data.message);
        } else {
          this.$toast.error("Erro ao salvar cliente!");
        }
      } finally {
        this.hideLoader();
      }
    },

    editClient(cliente) {
      this.habilitarConfiguracoesAvancadas = cliente.validacao !== null;
      this.habilitarConfiguracoesAvancadasAfter = cliente.validacao !== null;

      this.cliente = {
        id: cliente.id,
        ativo: cliente.ativo,
        horarioinicial: cliente.horarioinicial,
        horariofinal: cliente.horariofinal,
        intervalo: cliente.intervalo,
        idcliente: cliente.idcliente,
        nomecliente: cliente.cliente.nome,
        serialhd: cliente.serialhd,
        validacao: {
          tipobase:
            cliente.validacao !== null ? cliente.validacao.tipobase : "NORMAL",
          periodo:
            cliente.validacao !== null ? cliente.validacao.periodo : "SEMANAL",
        },
      };

      this.editing = true;
    },

    async saveEditClient() {
      const valid = await this.$refs.formEdit.validate();
      if (!valid) return;

      if (
        parseInt(this.cliente.horarioinicial) >
        parseInt(this.cliente.horariofinal)
      ) {
        this.$toast.error(
          "Horário final deve ser maior que o horário inicial!"
        );
        return;
      }

      this.showLoader("Salvando alterações...");

      try {
        if (
          this.habilitarConfiguracoesAvancadas == false &&
          this.habilitarConfiguracoesAvancadasAfter == true
        ) {
          await this.deteleConfigValidacaoCliente();
          delete this.cliente.validacao;
        }
        if (this.habilitarConfiguracoesAvancadas == false) {
          delete this.cliente.validacao;
        }

        const res = await csapi.put(
          `/sysafra/v1/sysbackup/clientes/${this.cliente.id}`,
          this.cliente
        );

        if (res.status === 200) {
          this.$toast.success("Alterações salvas com sucesso!");
          this.editing = false;
          this.getClientes();
        }
      } catch (error) {
        const { data } = error.response;
        if (data && data.message) {
          this.$toast.error(data.message);
        } else {
          this.$toast.error("Erro ao editar cliente!");
        }
      } finally {
        this.hideLoader();
      }
    },

    async saveDeleteClient() {
      const valid = await this.$refs.formDelete.validate();
      if (!valid) return;

      this.showLoader("Excluindo cliente...");
      try {
        const res = await csapi.delete(
          `/sysafra/v1/sysbackup/clientes/${this.cliente.id}`
        );

        if (res.status === 200) {
          this.$toast.success("Cliente excluído com sucesso!");
          this.deleting = false;
          this.getClientes();
        }
      } catch (error) {
        const { data } = error.response;
        if (data && data.message) {
          this.$toast.error(data.message);
        } else {
          this.$toast.error("Erro ao excluir cliente!");
        }
      } finally {
        this.hideLoader();
      }
    },

    async getClientesWithoutSysBackup() {
      this.loadingNewClientes = true;
      try {
        const res = await csapi.get("/v1/cliente/without-sysbackup");

        if (res.status === 200) {
          this.clientesWithoutSysBackup = res.data;
        }
      } catch (error) {
        console.error(error);
        this.$toast.error("Erro ao carregar clientes!");
      } finally {
        this.loadingNewClientes = false;
      }
    },
  },

  created() {
    if (!this.$store.state.app.accessSySafra) {
      this.$store.state.app.redirectToControl();
    }
  },

  mounted() {
    if (!this.$store.state.app.accessSySafra) return;

    this.isControl(false);
    this.setTitle("SySBackup - Clientes");
    this.getClientes();
  },
};
</script>

<style>
.clientesTable .v-data-table__wrapper {
  height: calc(100vh - 213px);
  overflow-y: auto;
}
</style>
